/**
 * Appointment Confirmation page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Geturlparameter from "../components/geturlparameter"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import Iconaptconf from "../images/icons/iconaptconf.inline.svg"
import Iconunableaptconf from "../images/icons/iconunableaptconf.inline.svg"
import "../styles/pages/appointment-confirmation.scss"

/* Page function declaration */
const AppointmentconfirmationPage = ({ data, search }) => {
  const { result } = search

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageAppointment
  const successMsg  = dataAcf.successMessage
  const errorMsg = dataAcf.errorMessage

  //CTA Footer page CONTACT
  var ctaFooterContact = data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "contact"
  })

  return (
    <Layout className="appointment">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        noindex="noindex"
      />

      {/* Section Hero */}
      <Hero colorShape="grey" classNamePage="appointment" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneraAptConfirm} contentH1={dataAcf.contentGeneralAptConfirm}/>

      {/* Main content */}
      <section className="main-section grey section-appointment-confirmation">
        <div className="content-general text">
          { result === "SUCCESS" || result === "ALREADY_CONFIRMED" ? [
            <div key="success" className="block-msg">
              <span><Iconaptconf /></span>
              <div dangerouslySetInnerHTML={{ __html:successMsg}} />
            </div>
          ] : [(result === "INTERNAL_ERROR" || result === "APPOINTMENT_NOT_FOUND" || result === "APPOINTMENT_TOO_FAR") && (
            <div key="error" className="block-msg">
              <span><Iconunableaptconf /></span>
              <div dangerouslySetInnerHTML={{ __html:errorMsg}} />
            </div>
          )]}
        </div>
      </section>

      {/* Section CtaFooter */}
      {ctaFooterContact.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="contact" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="grey" />
      })}

    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "appointment-confirmation"}) {
      acfPageAppointment {
        titleGeneralAptConfirm
        contentGeneralAptConfirm
        successMessage
        errorMessage
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`

/* Export page informations */
export default Geturlparameter(AppointmentconfirmationPage)
